const menu = [
  {
    id: 1,
    title: 'Blackforest Slice Cake',
    type: '',
    category: 'Sweet',
    price: "15.000",
    img: './images/blackforest.jpeg',
    desc: ``,
  },
  {
    id: 1222,
    title: 'Almond Choco Croissant',
    type: '',
    category: 'Sweet',
    price: "7.000",
    img: './images/almond_choco_croissant.jpeg',
    desc: ``,
  },
  {
    id: 76,
    title: 'Blackforest Cake (D20)',
    type: 'Whole Cake',
    category: '',
    price: "175.000",
    img: './images/blackforest.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'Brownies',
    type: '',
    category: 'Sweet',
    price: "3.500",
    img: './images/fudgy_brownies.jpeg',
    desc: ``,
  },
  {
    id: 89,
    title: 'Brownies (22cm)',
    type: 'Others',
    category: '',
    price: "85.000",
    img: './images/brownies_loyang.jpeg',
    desc: ``,
  },
  {
    id: 893,
    title: 'Custom Brownies',
    type: 'Others',
    category: '',
    price: "120.000",
    img: './images/custom_brownies.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: 'Brownies Lumer',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/brownies_lumer.jpeg',
    desc: ``,
  },
  {
    id: 72,
    title: 'Mini Brownies',
    type: '',
    category: 'Sweet',
    price: "30.000",
    img: './images/mini_brownies.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'Cake Potong Blackforest',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/cake_pot_black_forest.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'Cake Potong Klepon',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/cake_potong_klepon.jpeg',
    desc: ``,
  },
  {
    id: 8,
    title: 'Cake Potong Mocca',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/cake_potong_mocca.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: 'Cake Potong Meises Keju',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/cake_potong_moju.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'Cheese Burger (Beef)',
    type: '',
    category: 'Savory',
    price: "17.000",
    img: './images/cheese_burger_beef.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'Cheesecuit',
    type: '',
    category: 'Sweet',
    price: "10.000",
    img: './images/cheesecuit.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'Choco Devil Cake',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/chocolate_devil_single.jpeg',
    desc: ``,
  },
  {
    id: 121,
    title: 'Choco Devil Cake (20cm)',
    type: 'Others',
    category: '',
    price: "80.000",
    img: './images/choco_devil_cake.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'Chocolate Cheesecake Pudding',
    type: '',
    category: 'Sweet',
    price: "10.000",
    img: './images/chocolate_cheesecake_pudding.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'Cupcake Blueberry',
    type: '',
    category: 'Sweet',
    price: "8.000",
    img: './images/cupcake_blueberry.jpeg',
    desc: ``,
  },
  {
    id: 878,
    title: 'Creme Brulee',
    type: '',
    category: 'Sweet',
    price: "10.000",
    img: './images/creme_brulee.jpeg',
    desc: ``,
  },
  {
    id: 15,
    title: 'Cupcake Strawberry',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/cupcake_strawberry.jpeg',
    desc: ``,
  },
  {
    id: 153,
    title: 'Cupcake Red Velvet',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/red_velvet_cupcake.jpeg',
    desc: ``,
  },
  {
    id: 17,
    title: 'Donat',
    type: '',
    category: 'Sweet',
    price: "3.500",
    img: './images/donat.jpeg',
    desc: ``,
  },
  {
    id: 1732,
    title: 'Donat 1/2 Lusin',
    type: 'Others',
    category: '',
    price: "20.000",
    img: './images/donat_half_dozen.jpeg',
    desc: ``,
  },
  {
    id: 18,
    title: 'Donat Paha Ayam',
    type: '',
    category: 'Sweet',
    price: "7.000",
    img: './images/donat_paha_ayam.jpeg',
    desc: ``,
  },
  {
    id: 19,
    title: 'Eclair',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/eclair.jpeg',
    desc: ``,
  },
  {
    id: 20,
    title: 'Es Cokelat',
    type: 'Drink',
    category: '',
    price: "10.000",
    img: './images/es_cokelat.jpeg',
    desc: ``,
  },
  {
    id: 21,
    title: 'Es Kopi Susu',
    type: 'Drink',
    category: '',
    price: "10.000",
    img: './images/es_kopi_susu.jpeg',
    desc: ``,
  },
  {
    id: 2144,
    title: 'Smoothies Mixed Berries',
    type: 'Drink',
    category: '',
    price: "15.000",
    img: './images/mixed_berries_smoothies.jpeg',
    desc: ``,
  },
  {
    id: 2155,
    title: 'Smoothies Kiwiberry',
    type: 'Drink',
    category: '',
    price: "15.000",
    img: './images/kiwiberry_smoothies.jpeg',
    desc: ``,
  },
  {
    id: 2166,
    title: 'Smoothies Tropical Green',
    type: 'Drink',
    category: '',
    price: "15.000",
    img: './images/tropical_green_smoothies.jpeg',
    desc: ``,
  },
  {
    id: 71,
    title: 'Excavator Birthday Cake (D18)',
    type: 'Whole Cake',
    category: '',
    price: "215.000",
    img: './images/excavator_birthday_cake.jpeg',
    desc: ``,
  },
  {
    id: 22,
    title: 'Floss Roll Bread',
    type: '',
    category: 'Savory',
    price: "10.000",
    img: './images/floss_roll_bread.jpeg',
    desc: ``,
  },
  {
    id: 23,
    title: 'Hazelnut Burnt Cheesecake',
    type: '',
    category: 'Sweet',
    price: "15.000",
    img: './images/hazelnut_burnt_cheesecake.jpeg',
    desc: ``,
  },
  {
    id: 231,
    title: 'Burnt Cheesecake D16',
    type: 'Others',
    category: '',
    price: "135.000",
    img: './images/burnt_cheesecake_d16.jpeg',
    desc: ``,
  },
  {
    id: 232,
    title: 'Brownies Burnt Cheesecake',
    type: 'Others',
    category: '',
    price: "165.000",
    img: './images/brownies_burnt_cheesecake.jpeg',
    desc: ``,
  },
  {
    id: 24,
    title: 'Hokkaido Cheese Tart',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/hokkaido_cheese_tart.jpeg',
    desc: ``,
  },
  {
    id: 25,
    title: 'Hunkwe Nangka',
    type: '',
    category: 'Sweet',
    price: "2.500",
    img: './images/hunkwe_nangka.jpeg',
    desc: ``,
  },
  {
    id: 27,
    title: 'Kaswi',
    type: '',
    category: 'Sweet',
    price: "1.500",
    img: './images/kaswi.jpeg',
    desc: ``,
  },
  {
    id: 799,
    title: 'Klepon Cake(D20)',
    type: 'Whole Cake',
    category: '',
    price: "135.000",
    img: './images/cake_klepon.jpeg',
    desc: ``,
  },
  {
    id: 7929,
    title: 'Klappertaart',
    type: '',
    category: 'Sweet',
    price: "7.000",
    img: './images/klappertaart.jpeg',
    desc: ``,
  },
  {
    id: 28,
    title: 'Kroket Ayam',
    type: '',
    category: 'Savory',
    price: "4.000",
    img: './images/kroket_ayam.jpeg',
    desc: ``,
  },
  {
    id: 30,
    title: 'Kue Lapis Bunga',
    type: '',
    category: 'Sweet',
    price: "2.000",
    img: './images/kue_lapis_bunga.jpeg',
    desc: ``,
  },
  {
    id: 31,
    title: 'Lapis Roll Strawberry',
    type: '',
    category: 'Sweet',
    price: "2.500",
    img: './images/lapis_roll_strawberry.jpeg',
    desc: ``,
  },
  {
    id: 32,
    title: 'Lemper Ayam',
    type: '',
    category: 'Savory',
    price: "4.000",
    img: './images/lemper_ayam.jpeg',
    desc: ``,
  },
  {
    id: 33,
    title: 'Lumpia Ayam Udang',
    type: '',
    category: 'Savory',
    price: "4.000",
    img: './images/lumpia_ayam_udang.jpeg',
    desc: ``,
  },
  {
    id: 3376,
    title: 'Kue Cumcum',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/kue_cum_cum.jpeg',
    desc: ``,
  },
  {
    id: 34,
    title: 'Martabak Daging',
    type: '',
    category: 'Savory',
    price: "4.000",
    img: './images/martabak_daging.jpeg',
    desc: ``,
  },
  {
    id: 73,
    title: 'Mango Birthday Pudding (D18)',
    type: 'Whole Cake',
    category: '',
    price: "185.000",
    img: './images/mango_birthday_pudding.jpeg',
    desc: ``,
  },
  {
    id: 923,
    title: 'Mango Salad',
    type: '',
    category: 'Sweet',
    price: "8.000",
    img: './images/mango_salad.jpeg',
    desc: ``,
  },
  {
    id: 2,
    title: 'Meat Bolognese',
    type: '',
    category: 'Savory',
    price: "10.000",
    img: './images/meat_bolognese.jpeg',
    desc: ``,
  },
  {
    id: 35,
    title: 'Mini Burger',
    type: '',
    category: 'Savory',
    price: "5.000",
    img: './images/mini_burger.jpeg',
    desc: ``,
  },
  {
    id: 52,
    title: 'Mille Crepes Red Velvet (D16)',
    type: 'Whole Cake',
    category: '',
    price: "100.000",
    img: './images/red_velvet_mille_crepes.jpeg',
    desc: ``,
  },
  {
    id: 593,
    title: 'Mille Crepes Red Velvet Slice',
    type: '',
    category: 'Sweet',
    price: "18.000",
    img: './images/red_velvet_mille_crepes.jpeg',
    desc: ``,
  },
  {
    id: 65,
    title: 'Mille Crepes Strawberry',
    type: 'Whole Cake',
    category: '',
    price: "100.000",
    img: './images/strawberry_mille_crepes.jpeg',
    desc: ``,
  },
  {
    id: 651,
    title: 'Mille Crepes Strawberry Slice',
    type: '',
    category: 'Sweet',
    price: "18.000",
    img: './images/mille_crepes_slice.jpeg',
    desc: ``,
  },
  {
    id: 78,
    title: 'Milk Chocolate Ganache Cake (D20)',
    type: 'Whole Cake',
    category: '',
    price: "115.000",
    img: './images/milk_ganache_cake.jpeg',
    desc: ``,
  },
  {
    id: 783,
    title: 'Milk Chocolate Ganache Slice Cake',
    type: '',
    category: 'Sweet',
    price: "15.000",
    img: './images/milk_ganache_slice_cake.jpeg',
    desc: ``,
  },
  {
    id: 36,
    title: 'Mochi Strawberry',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/mochi_strawberry.jpeg',
    desc: ``,
  },
  {
    id: 6990,
    title: 'Mochi Choco Truffle',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/mochi_choco_truffle.jpeg',
    desc: ``,
  },
  {
    id: 85,
    title: 'Muffin Banana Choco',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/banana_choco_muffin.jpeg',
    desc: ``,
  },
  {
    id: 37,
    title: 'Muffin Silverqueen',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/muffin_silverqueen.jpeg',
    desc: ``,
  },
  {
    id: 38,
    title: 'One Bite Fruit Pie',
    type: '',
    category: 'Sweet',
    price: "2.000",
    img: './images/one_bite_fruit_pie.jpeg',
    desc: ``,
  },
  {
    id: 38999,
    title: 'One Box One Bite Fruit Pie',
    type: 'Others',
    category: '',
    price: "55.000",
    img: './images/one_box_one_bite_fruit_pie.jpeg',
    desc: ``,
  },
  {
    id: 39,
    title: 'Curry Puff',
    type: '',
    category: 'Savory',
    price: "4.000",
    img: './images/curry_puff.jpeg',
    desc: ``,
  },
  {
    id: 40,
    title: 'Pie Buah',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/pie_buah.jpeg',
    desc: ``,
  },
  {
    id: 41,
    title: 'Pie Choco',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/pie_choco.jpeg',
    desc: ``,
  },
  {
    id: 413343,
    title: 'Pie Susu',
    type: '',
    category: 'Sweet',
    price: "3.500",
    img: './images/photo_coming_soon.jpg',
    desc: ``,
  },
  {
    id: 42,
    title: 'Pie Buah Oval',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/pie_buah_oval.jpeg',
    desc: ``,
  },
  {
    id: 43,
    title: 'Pudding Cokelat',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/pudding_cokelat.jpeg',
    desc: ``,
  },
  {
    id: 44,
    title: 'Pudding Jagung',
    type: '',
    category: 'Sweet',
    price: "1.500",
    img: './images/pudding_jagung.jpeg',
    desc: ``,
  },
  {
    id: 46,
    title: 'Pudding Mangga',
    type: '',
    category: 'Sweet',
    price: "3.500",
    img: './images/pudding_mangga.jpeg',
    desc: ``,
  },
  {
    id: 48,
    title: 'Pudding Susu Buah',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/pudding_buah.jpeg',
    desc: ``,
  },
  {
    id: 49,
    title: 'Pudding Susu Karamel',
    type: '',
    category: 'Sweet',
    price: "3.500",
    img: './images/photo_coming_soon.jpg',
    desc: ``,
  },
  {
    id: 50,
    title: 'Pudding Tiramisu',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/photo_coming_soon.jpg',
    desc: ``,
  },
  {
    id: 51,
    title: 'Putu Ayu',
    type: '',
    category: 'Sweet',
    price: "3.000",
    img: './images/putu_ayu.jpeg',
    desc: ``,
  },
  {
    id: 769,
    title: 'Quiche',
    type: '',
    category: 'Savory',
    price: "3.500",
    img: './images/quiche.jpeg',
    desc: ``,
  },
  {
    id: 769333,
    title: 'Quiche (D20',
    type: 'Others',
    category: '',
    price: "120.000",
    img: './images/quiche_loyang.jpeg',
    desc: ``,
  },
  {
    id: 53,
    title: 'Red Velvet Slice Cake',
    type: '',
    category: 'Sweet',
    price: "15.000",
    img: './images/red_velvet_slice_cake.jpeg',
    desc: ``,
  },
  {
    id: 79,
    title: 'Red Velvet Cake (D16)',
    type: 'Whole Cake',
    category: '',
    price: "145.000",
    img: './images/red_velvet_cake.jpeg',
    desc: ``,
  },
  {
    id: 54,
    title: 'Risol Bolognese',
    type: '',
    category: 'Savory',
    price: "5.000",
    img: './images/risol_bolognese.jpeg',
    desc: ``,
  },
  {
    id: 55,
    title: 'Risol Ragout Ayam',
    type: '',
    category: 'Savory',
    price: "4.000",
    img: './images/risol_ragout_ayam.jpeg',
    desc: ``,
  },
  {
    id: 56,
    title: 'Risol Smoked Beef',
    type: '',
    category: 'Savory',
    price: "5.000",
    img: './images/risol_smoked_beef.jpeg',
    desc: ``,
  },
  {
    id: 57,
    title: 'Risol Tuna Mayo',
    type: '',
    category: 'Savory',
    price: "5.000",
    img: './images/risol_tuna_mayo.jpeg',
    desc: ``,
  },
  {
    id: 58,
    title: 'Bolu Gulung Cokelat',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/bolu_gulung_cokelat.jpeg',
    desc: ``,
  },
  {
    id: 59,
    title: 'Bolu Gulung Red Velvet',
    type: '',
    category: 'Sweet',
    price: "3.000",
    img: './images/roll_cake_red_velvet.jpeg',
    desc: ``,
  },
  {
    id: 60,
    title: 'Roti Pizza',
    type: '',
    category: 'Savory',
    price: "5.000",
    img: './images/roti_pizza.jpeg',
    desc: ``,
  },
  {
    id: 61,
    title: 'Smoked Beef & Egg Sandwich',
    type: '',
    category: 'Savory',
    price: "17.000",
    img: './images/smoked_beef_egg_sandwich.jpeg',
    desc: ``,
  },
  {
    id: 62,
    title: 'Soes Buah',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/soes_buah.jpeg',
    desc: ``,
  },
  {
    id: 623,
    title: 'Soes',
    type: '',
    category: 'Sweet',
    price: "3.000",
    img: './images/kue_soes.jpeg',
    desc: ``,
  },
  {
    id: 63,
    title: 'Spaghetti Brulee',
    type: '',
    category: 'Savory',
    price: "10.000",
    img: './images/spaghetti_bolognese.jpeg',
    desc: ``,
  },
  {
    id: 64,
    title: 'Strawberry Cheesecake',
    type: '',
    category: 'Sweet',
    price: "13.000",
    img: './images/strawberry_cheesecake.jpeg',
    desc: ``,
  },
  {
    id: 66,
    title: 'Strawberry Shortcake',
    type: 'Whole Cake',
    category: '',
    price: "125.000",
    img: './images/strawberry_shortcake.jpeg',
    desc: ``,
  },
  {
    id: 663,
    title: 'Strawberry Shortcake',
    type: '',
    category: 'Sweet',
    price: "15.000",
    img: './images/strawberry_shortcake_slice.jpeg',
    desc: ``,
  },
  {
    id: 80,
    title: 'Cake Potong Tres Leches',
    type: '',
    category: 'Sweet',
    price: "8.000",
    img: './images/tres_leches_slice.jpeg',
    desc: ``,
  },
  {
    id: 67,
    title: 'Tres Leches Cake',
    type: 'Others',
    category: '',
    price: "110.000",
    img: './images/tres_leches_cake.jpeg',
    desc: ``,
  },
  {
    id: 67323,
    title: 'Quiche (D20)',
    type: 'Others',
    category: '',
    price: "120.000",
    img: './images/quiche_loyang.jpeg',
    desc: ``,
  },
  {
    id: 68,
    title: 'Ultimate Chocolate Cake (D18)',
    type: 'Whole Cake',
    category: '',
    price: "115.000",
    img: './images/ultimate_chocolate_cake.jpeg',
    desc: ``,
  },
  {
    id: 74,
    title: 'Ultimate Chocolate Slice Cake',
    type: '',
    category: 'Sweet',
    price: "15.000",
    img: './images/ultimate_chocolate_slice_cake.jpeg',
    desc: ``,
  },
  {
    id: 75,
    title: 'Strawberry Chocolate Cake',
    type: 'Whole Cake',
    category: '',
    price: "150.000",
    img: './images/chocolate_cake_strawberry.jpeg',
    desc: ``,
  },
  {
    id: 69,
    title: 'Vanilla Custard Pastry',
    type: '',
    category: 'Sweet',
    price: "8.000",
    img: './images/vanilla_custart.jpeg',
    desc: ``,
  },
  {
    id: 70,
    title: 'Ximilu',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/ximilu.jpeg',
    desc: ``,
  },
  {
    id: 701,
    title: 'Ximilu Botol',
    type: '',
    category: 'Sweet',
    price: "10.000",
    img: './images/bottled_ximilu.jpeg',
    desc: ``,
  },
  {
    id: 733,
    title: 'Cheesetart',
    type: '',
    category: 'Sweet',
    price: "125.000",
    img: './images/cheesetart.jpeg',
    desc: ``,
  },
  {
    id: 7331,
    title: 'Cheesetart Slice',
    type: '',
    category: 'Sweet',
    price: "15.000",
    img: './images/cheesetart_slice.jpeg',
    desc: ``,
  },
  {
    id: 88,
    title: 'Beef Samosa',
    type: '',
    category: 'Savory',
    price: "4.000",
    img: './images/beef_samosa.jpeg',
    desc: ``,
  },
  {
    id: 913,
    title: 'Chocochip Cookies',
    type: '',
    category: 'Sweet',
    price: "3.500",
    img: './images/chocochip_cookies.jpeg',
    desc: ``,
  },
  {
    id: 90,
    title: 'Pudding Choco Silverqueen',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/pudding_choco_silverqueen.jpeg',
    desc: ``,
  },
  {
    id: 903,
    title: 'Mini Cheesetart',
    type: '',
    category: 'Sweet',
    price: "4.000",
    img: './images/mini_cheesetart.jpeg',
    desc: ``,
  },
  {
    id: 91,
    title: 'Cake Potong Thailand',
    type: '',
    category: 'Sweet',
    price: "5.000",
    img: './images/cake_potong_thailand.jpeg',
    desc: ``,
  },
  {
    id: 92,
    title: 'Pancake Durian',
    type: '',
    category: 'Sweet',
    price: "10.000",
    img: './images/pancake_durian.jpeg',
    desc: ``,
  }
];

export default menu;
